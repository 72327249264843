import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from "../../components/layout"
import SEO from "../../components/seo-component.js"

// import Box from 'components/box';
// import Head from 'components/head';
// import Registration from 'components/registration';
// import Registration from 'components/forms/reports/risk-report';
// import Registration from 'components/forms/reports/Form';


// import SubscribeCTA from "../../components/subscribe-cta"

let schedulerLink = process.env.REACT_APP_CALENDLY_LINK2 || '#';

let title = "Let's talk about your Organization as a Provider"

import { Link } from 'gatsby';
const RiskReport = () => (
  <div>
  <SEO title={title} />
    <Layout>
      <div className="jumbotron page-header">
        <div className="container">
              <h1 className="display-32">{title}</h1>
          <h2 className="display-32">
          Get key provider insights to increase trust with customers today.{' '}
            <span style={{ color: '#2449fc' }}>
              Focus on what matters.
            </span>
          </h2>
        </div>
      </div>
      <div className="container">
  <div className="row">
    <div className="col-sm-12 post-content text-center">
      <h3>Let’s Setup a Time to Talk</h3>
      {/* <p>We’re happy to setup a call and see if we can be of value to you!</p> */}
      <p>{/* Calendly link widget begin

<a className="btn btn-black mb32">Schedule a free introductory 30 mins with us.</a>
 Calendly link widget end */}</p>
      <p>{/* Calendly link widget begin */}
        

        <br />
        <a className="btn btn-primary mb32 text-white" 
        // href={schedulerLink}
         onClick={() => eval("Calendly.showPopupWidget('"+schedulerLink+"')")}  
         >Schedule a no-cost introductory 30 mins conversation with us.</a><br />
        {/* Calendly link widget end */}</p>
      <p>{/* Calendly inline widget begin */}<br />
        {/*


<div className="calendly-inline-widget" data-url={process.env.REACT_APP_CALENDLY_LINK} style="min-width:320px;height:400px;"></div>


*/}<br />
        {/* Calendly inline widget end */}</p>
      <p />
    </div>
  </div>
</div>
   

    </Layout>
  </div>
);

export default RiskReport;
